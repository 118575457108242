import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { useKillRaceActive } from '../api/useKillRaceActive'
import { Countdown } from './Countdown'

const ASSET_URL = '/media/assets/features/career'
const DEFAULT_BANNERS = ['killrace_24_bg.png', 'killrace_48_bg.png']

const stopwatch_icon = new URL('../assets/stopwatch_icon.svg', import.meta.url).href

export const KillRaceActiveGrid = () => {
    const { data } = useKillRaceActive()
    return (
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 w-full place-content-center'>
            {data?.length == 0 && (
                <div className='col-span-2 font-black text-center bg-white/10 p-2'>NO LIVE TOURNAMENTS</div>
            )}
            {data?.map(({ uuid, name, banner, prize, remaining_seconds, ladder_size }, i) => (
                <Link to={`/kill-race/info/${uuid}`} key={uuid}>
                    <div className='relative'>
                        <img
                            src={`${ASSET_URL}/${banner || DEFAULT_BANNERS[i % 2 == 0 ? 0 : 1]}`}
                            className='object-cover w-full'
                        />
                        <h1 className='absolute top-2 right-2 uppercase text-xl md:text-2xl font-black text-right flex flex-col gap-2'>
                            {name}
                        </h1>
                        <h1 className='absolute bottom-2 right-2 uppercase text-3xl md:text-5xl font-black text-[#00FF90]'>
                            {prize}$
                        </h1>
                    </div>
                    <div className='flex justify-between'>
                        <h1 className=' uppercase text-xl md:text-2xl font-black text-white flex gap-2 items-center pl-2 mt-2'>
                            <img src={stopwatch_icon} />
                            <Countdown seconds={remaining_seconds} />
                        </h1>
                        <h1 className=' uppercase text-xl md:text-2xl font-black text-white flex gap-2 items-center pr-2 mt-2'>
                            <FontAwesomeIcon icon={faTrophy} className='text-sm' />
                            <span>TOP {ladder_size}</span>
                        </h1>
                    </div>
                </Link>
            ))}
        </div>
    )
}
