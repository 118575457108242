import { createPortal } from 'react-dom'
import { Outlet } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { s3 } from '~/utils/s3'

const VIDEO = s3('/video/signup')

const Background = (props) => {
    const { className } = props
    const page = document.querySelector<HTMLElement>('#page-background')

    return (
        page &&
        createPortal(
            <div className={twMerge('', className)}>
                <video autoPlay muted loop playsInline className='object-cover w-full h-screen'>
                    <source src={`${VIDEO}.1080.mp4`} type='video/mp4' media='(min-width: 1920px)' />
                    <source src={`${VIDEO}.720.mp4`} type='video/mp4' />
                </video>

                <div className='absolute top-0 w-full h-[55%] bg-gradient-to-b from-black to-transparent'></div>
                <div className='absolute bottom-0 w-full h-[35%] bg-gradient-to-t from-black to-transparent'></div>
                <div className='absolute inset-0 bg-[image:radial-gradient(circle,black_0%,black_30%,transparent_30%)] bg-[size:2px_2px]'></div>
            </div>,
            page
        )
    )
}
export const LoginLayout = () => {
    return (
        <>
            <Background />
            <div className='min-h-[75vh]'>
                <Outlet />
            </div>
        </>
    )
}
