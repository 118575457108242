import { twMerge } from 'tailwind-merge'
import { useUser } from '~/features/auth/api/useUser'
import { useKillRaceLeaderboard } from '../api/useKillRaceLeaderboard'

export const KillRaceLeaderboard = ({ uuid }) => {
    const { data, isFetched } = useKillRaceLeaderboard(uuid)
    const { data: user } = useUser()

    const career = data?.filter((row) => row.user_id == user?.id)[0]

    console.log(data)

    return (
        <table className='font-bold border-separate border-spacing-y-2 w-full'>
            <thead className='sticky top-0 z-10 hidden lg:table-header-group'>
                <tr className='bg-black h-12 text-xs font-bold'>
                    <th className='w-12'></th>
                    <th className='text-white/50 text-left pr-4 border-l-8 border-black'>PLAYER</th>
                    <th className='text-white/50 text-left pr-4'>KILLS</th>
                    <th className='text-white/50 text-left pr-4'>SESSIONS</th>
                    <th className='text-white/50 text-left pr-4'>K/D</th>
                    <th className='text-white/50 text-center'>EARNINGS</th>
                    <th className='text-white/50 text-center'>POTENTIAL REWARD</th>
                </tr>

                {career ? (
                    <>
                        <Row
                            data={career}
                            className='bg-black lg:h-12'
                            style={{
                                backgroundImage:
                                    'linear-gradient(99deg, rgba(201, 31, 255, 0.50) -2.44%, rgba(3, 206, 255, 0.50) 100%)',
                            }}
                        />

                        <tr>
                            <td className=' h-5'></td>
                        </tr>
                    </>
                ) : null}
            </thead>
            <tbody className='text-sm'>
                {!isFetched ? (
                    <LoadingRows />
                ) : data.length == 0 ? (
                    <tr>
                        <td colSpan={100} className='text-center text-white/50 py-4 bg-white/5'>
                            NO SESSIONS DATA
                        </td>
                    </tr>
                ) : (
                    data.map((user, j) => (
                        <Row
                            key={j}
                            pos={j}
                            data={user}
                            className='lg:h-10 bg-white/5'
                            style={{
                                backgroundImage: [
                                    'linear-gradient(100deg, rgba(50, 42, 0, 0.50) -8.17%, rgba(255, 214, 0, 0.00) 112.69%)',
                                    'linear-gradient(100deg, rgba(183, 183, 183, 0.50) -8.17%, rgba(135, 135, 135, 0.00) 112.69%)',
                                    'linear-gradient(100deg, rgba(41, 22, 0, 0.50) -8.17%, rgba(125, 68, 0, 0.00) 112.69%)',
                                ][j],
                            }}
                        />
                    ))
                )}
            </tbody>
        </table>
    )
}

const Row = ({ data, className = undefined, style = undefined, pos = undefined }) => {
    return (
        <tr
            className={twMerge(
                'flex flex-col p-1 gap-2 my-4 border border-white/20',
                'lg:table-row lg:h-10 lg:p-0 lg:gap lg:border-0',
                className
            )}
            style={style}
        >
            <td className=''>
                <div
                    className={twMerge(
                        'lg:h-10 lg:w-12 flex flex-row lg:items-center lg:justify-center',
                        [0, 1, 2].includes(pos) && 'border border-white/20'
                    )}
                >
                    {data?.rank}
                </div>
            </td>
            <td className='grid grid-cols-2 gap-2 lg:table-cell text-left lg:border-l-8 border-black lg:px-2 before:content-["PLAYER:_"] lg:before:content-none'>
                {data?.username}
            </td>
            <td className='grid grid-cols-2 gap-2 lg:table-cell text-left before:content-["KILLS:_"] lg:before:content-none text-white/50 select-none'>
                {data?.kills}
            </td>
            <td className='grid grid-cols-2 gap-2 lg:table-cell text-left before:content-["SESSIONS:_"] lg:before:content-none text-white/50 select-none'>
                {data?.sessions}
            </td>
            <td className='grid grid-cols-2 gap-2 lg:table-cell text-left before:content-["K/D:_"] lg:before:content-none text-white/50 select-none'>
                {data?.kill_death_ratio}
            </td>
            <td className='grid grid-cols-2 gap-2 lg:table-cell lg:text-center before:content-["EARNINGS:_"] lg:before:content-none text-white/50 select-none'>
                {data?.earnings}
            </td>

            <td className='grid grid-cols-2 gap-2 lg:table-cell lg:text-center before:content-["POTENTIAL_REWARD:_"] lg:before:content-none'>
                ${data?.potential_reward}
            </td>
        </tr>
    )
}

const LoadingRows = () => {
    return Array.from({ length: 100 }).map((_, i) => (
        <Row key={i} data={undefined} className='lg:h-10 bg-white/5 animate-pulse' />
    ))
}
