import { useMemo } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import NotFound from '~/components/NotFound'
import { useViewer } from '../api'
import { LoginLayout } from '../components'
import { ActivationPage } from './ActivationPage'
import { LoginPage } from './LoginPage'
import { PasswordResetPage } from './PasswordResetPage'
import { PasswordSetPage } from './PasswordSetPage'
import { SignupPage } from './SignupPage'

export const AuthRoutes = () => {
    const { data: viewer, isFetched } = useViewer()

    const authenticated = useMemo(() => viewer?.is_auth, [viewer])
    const activated = useMemo(() => viewer?.is_auth && viewer?.email_confirmed, [viewer])
    const wallet_guide_seen = useMemo(() => viewer?.wallet_guide_seen, [viewer])

    return (
        <Routes>
            <Route path='/' element={<LoginLayout />}>
                <Route
                    element={
                        activated ? (
                            !wallet_guide_seen ? (
                                <Navigate to='/wallet' replace />
                            ) : (
                                <Navigate to='/dashboard' replace />
                            )
                        ) : (
                            <Outlet />
                        )
                    }
                >
                    <Route element={authenticated ? <Navigate to='/activate' replace /> : <Outlet />}>
                        <Route path='signup' element={<SignupPage />} />
                        <Route path='login' element={<LoginPage />} />
                        <Route path='password-reset' element={<PasswordResetPage />} />
                    </Route>
                </Route>
                <Route
                    element={
                        activated ? (
                            !wallet_guide_seen ? (
                                <Navigate to='/wallet' replace />
                            ) : (
                                <Navigate to='/dashboard' replace />
                            )
                        ) : (
                            <Outlet />
                        )
                    }
                >
                    <Route element={!authenticated ? <Navigate to='/login' replace /> : <Outlet />}>
                        <Route path='activate' element={<ActivationPage />} />
                    </Route>
                </Route>

                <Route
                    path='password-set/:uid/:token'
                    element={authenticated ? <Navigate to='/account' /> : <PasswordSetPage />}
                />
            </Route>
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}
