import { Route } from 'react-router-dom'

import { RequireAuth } from '~/components/Auth'
import { useKillRaceList } from '../hooks/useKillRaceList'
import { KillRace } from './KillRace'
import { KillRaceInfoPage } from './KillRaceInfoPage'
import { RacePage } from './RacePage'

export const KillRaceRoutes = () => {
    const { enabled } = useKillRaceList()
    const routes = [
        <Route
            index
            key='index'
            element={
                <RequireAuth>
                    <KillRace />
                </RequireAuth>
            }
        />,
        <Route
            path='info/:uuid'
            key='info'
            element={
                <RequireAuth>
                    <KillRaceInfoPage />
                </RequireAuth>
            }
        />,
        enabled.map((race) => (
            <Route
                path={race.type}
                key={race.type}
                element={
                    <RequireAuth>
                        <RacePage type={race.type} />
                    </RequireAuth>
                }
            />
        )),
    ]

    return routes
}
