import { useQuery } from '@tanstack/react-query'

const API_URL = '/api/game/killrace/active'

export const useKillRaceActive = () => {
    return useQuery({
        queryKey: ['kill_race', 'live'],
        queryFn: async () => {
            const res = await fetch(API_URL)
            return await res.json()
        },
        retry: false,
    })
}
