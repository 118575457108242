import { useQuery } from '@tanstack/react-query'

const API_URL = '/api/game/killrace/info'

export const useKillRaceInfo = (uuid) => {
    return useQuery({
        queryKey: ['kill_race', 'info', uuid],
        queryFn: async () => {
            const res = await fetch(`${API_URL}/${uuid}`)
            return await res.json()
        },
        retry: false,
    })
}
