import { useQuery } from '@tanstack/react-query'

const API_URL = '/api/game/killrace/leaderboard'

export const useKillRaceLeaderboard = (uuid) => {
    return useQuery({
        queryKey: ['kill_race', 'leaderboard', uuid],
        queryFn: async () => {
            const res = await fetch(`${API_URL}/${uuid}`)
            return await res.json()
        },
        retry: false,
    })
}
